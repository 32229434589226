import React, { useState, useRef, useCallback } from "react";
import { useIntl } from "react-intl";
import AnnouncementIndicator from "components/announcement-indicator";
import UserMenu from "components/user-menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/SearchRounded";
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Grid,
  IconButton,
  alpha,
  Button,
  Menu,
  MenuItem,
  useTheme,
  styled,
} from "@mui/material";
import SiteLogo from "svg/SiteLogo";
import { Link, useLocation } from "react-router-dom";

const StyledDiv = styled("div")(() => ({}));

interface AppBarProps {
  term: string;
  onTermUpdate: (term: string) => void;
  handleSearch: (term: string) => void;
}

function MenuBar({
  term,
  onTermUpdate,
  handleSearch,
}: AppBarProps): JSX.Element {
  const theme = useTheme();

  const input = {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    "&.MuiInputBase-root": {
      color: "inherit",
      width: "100%",
    },
  };
  const search = {
    position: "relative",
    borderRadius: 16,
    borderColor: theme.palette.grey[500],
    color: theme.palette.grey[500],
    borderStyle: "solid",
    borderWidth: "1px",
    backgroundColor: alpha(theme.palette.grey[100], 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.grey[300], 0.25),
    },
    marginLeft: theme.spacing(3),
  };
  const searchIcon = {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme.palette.grey[500],
    width: "100%",
  };
  const appBar = {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.text.primary,
    boxShadow: "none",
    background: "transparent",
  };
  const appText = {
    color: theme.palette.grey[500],
  };
  const selected = {
    color: theme.palette.primary.main,
    borderBottom: "2px solid",
  };
  const menuItem = {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  };
  const menuButton = {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  };

  const intl = useIntl();
  const menuEl = useRef(null);
  const [open, setOpen] = useState(false);
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      const body = new FormData(e.target as HTMLFormElement);
      handleSearch(body.get("search-term") as string);
    },
    [handleSearch],
  );
  return (
    <AppBar position="static" sx={appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          sx={menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => setOpen(true)}
          ref={menuEl}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={menuEl.current}
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={() => setOpen(false)}
        >
          <MenuItem>
            <Button component={Link} to="/">
              Home
            </Button>
          </MenuItem>
          <MenuItem>
            <Button component={Link} to="/explore">
              Explore Model
            </Button>
          </MenuItem>
          <MenuItem>
            <Button component={Link} to="/whats_new">
              What's New
            </Button>
          </MenuItem>
        </Menu>
        <SiteLogo />
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          wrap="nowrap"
        >
          <Grid item sx={menuItem}>
            <Button component={Link} to="/">
              <Typography
                sx={
                  useLocation().pathname == "/"
                    ? { ...appText, ...selected }
                    : appText
                }
                noWrap
              >
                Home
              </Typography>
            </Button>
          </Grid>
          <Grid item sx={menuItem}>
            <Button component={Link} to="/explore">
              <Typography
                sx={
                  useLocation().pathname.startsWith("/explore")
                    ? { ...appText, ...selected }
                    : appText
                }
                noWrap
              >
                Explore Model
              </Typography>
            </Button>
          </Grid>
          <Grid item sx={menuItem}>
            <Button component={Link} to="/whats_new">
              <Typography
                sx={
                  useLocation().pathname.startsWith("/whats_new")
                    ? { ...appText, ...selected }
                    : appText
                }
                noWrap
              >
                What's New
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={10} sx={search}>
            <StyledDiv sx={searchIcon}>
              <SearchIcon />
            </StyledDiv>
            <form onSubmit={handleSubmit}>
              <InputBase
                placeholder={intl.formatMessage({
                  id: "App.search",
                  defaultMessage: "Search...",
                })}
                sx={input}
                name="search-term"
                inputProps={{ "aria-label": "search", name: "search-term" }}
                value={term}
                onChange={(e) => onTermUpdate(e.target.value)}
              />
            </form>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            wrap="nowrap"
          >
            <Grid item>
              <AnnouncementIndicator />
            </Grid>
            <Grid item>
              <UserMenu />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default MenuBar;
